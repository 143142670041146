import React from 'react';

interface layoutProps {
  description: string;
  image: any;
  alt: string;
}

const Introduction: React.FC<layoutProps> = ({ description, image, alt }) => {
  return (
    <div>
      <div className="my-10">{description}</div>
      <img src={image} alt={alt} className="rounded lg:w-2/3 mx-auto" />
    </div>
  );
};

export default Introduction;
